<template>
  <v-container>
    <v-row class="pt-12">
      <v-col>
        <h1 class="text-center">이용 제한 시간입니다.</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="text-center">ISK Customer Support System 운영시간</h3>
        <h3 class="text-center">
          {{ this.servicedown.showStartTime }} ~
          {{ this.servicedown.showEndTime }} (Mon ~ Fri)
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col col="12" md="8" v-html="this.servicedown.downMessage"> </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>
<script>
import { updateSystemSettings } from "@/api/System";
export default {
  mounted() {
    this.servicedown.state = this.SERVICE_DOWN_ON;
    this.servicedown.startTime = this.SERVICE_START_TIME;
    this.servicedown.endTime = this.SERVICE_END_TIME;
    this.servicedown.downMessage = this.SERVICE_DOWN_MESSAGE;

    this.servicedown.showStartTime =
      this.servicedown.startTime.split(":")[0] +
      ":" +
      this.servicedown.startTime.split(":")[1];
    this.servicedown.showEndTime =
      this.servicedown.endTime.split(":")[0] +
      ":" +
      this.servicedown.endTime.split(":")[1];
  },
  methods: {
    async changeMaintenace(value) {
      const data = value ? 1 : 0;
      await updateSystemSettings("IS_CHECK_TIME", data);
    },
    async changeServiceDown(value) {
      const data = value ? 1 : 0;
      await updateSystemSettings("SERVICE_DOWN_ON", data);
    },
  },
  computed: {
    SERVICE_DOWN_ON() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_DOWN_ON) {
        return true;
      }
      return false;
    },
    SERVICE_DOW() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_DOW) {
        return true;
      }
      return false;
    },
    SERVICE_START_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_START_TIME) {
        return publicSetting.SERVICE_START_TIME;
      }
      return "09:00:00";
    },
    SERVICE_END_TIME() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_END_TIME) {
        return publicSetting.SERVICE_END_TIME;
      }
      return "18:00:00";
    },
    SERVICE_DOWN_MESSAGE() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (publicSetting.SERVICE_DOWN_MESSAGE) {
        return publicSetting.SERVICE_DOWN_MESSAGE;
      }
      return "";
    },
  },
  data() {
    return {
      servicedown: {
        state: false,
        showStartTime: "09:00",
        showEndTime: "18:00",
        startTime: "09:00:00",
        endTime: "18:00:00",
        downMessage: "",
      },
    };
  },
};
</script>
